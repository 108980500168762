export const environment = {
  production: true,
  redirectUri: 'https://pre-prd.yoda.ienergy.halliburton.com',
  clientId: '29336e95-76b2-41e3-b79b-2237059b90f7',
  authority:
    'https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5',
  scopes: '29336e95-76b2-41e3-b79b-2237059b90f7/.default',
  api: 'https://preprd-api.yoda.ienergy.halliburton.com',
  vapidPublicKey:
    'BPNLY7kjoP-nY5hAXL9SF5XUVZCNJKq_VCTd8kdP6fBhT2uF4s-sz-p46OVRw3WAallGc_P-TikfiD2Uy6-Idhs',
};
